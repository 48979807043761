
import { defineComponent, computed } from "vue";
import DateInput from "@/components/input/DateInput.vue";
import PalveluyksikotInput from "@/components/PalveluyksikotInput.vue";
import ValvontatyyppiInput from "@/modules/valvontatiedot/components/input/ValvontatyyppiInput.vue";
import OrganisaatioInput from "@/components/input/OrganisaatioInput.vue";
import { emailRegex, phoneRegex } from "@/utils/regex";
import { localStringToDate } from "@/utils/date";

export default defineComponent({
    components: { OrganisaatioInput, DateInput, PalveluyksikotInput, ValvontatyyppiInput },
    props: {
        modelValue: Object,
        filteredYksikot: Function,
        showError: Boolean,
        disabled: Boolean,
    },
    emits: ["update:modelValue"],
    setup(props, { emit }) {
        const form: any = computed({
            get: () => props.modelValue,
            set: (value) => emit("update:modelValue", value),
        });

        const phoneIsValid = computed(() => phoneRegex.test(form.value.author_phone));
        const emailIsValid = computed(() => emailRegex.test(form.value.author_email));

        return {
            form,
            phoneIsValid,
            emailIsValid,
            localStringToDate,
        };
    },
});
