import { phoneRegex, emailRegex } from "@/utils/regex";
import { formatDate, localStringToDate } from "@/utils/date";

export const filledFields = (form: any) => {
    return (
        form.palveluyksikko &&
        form.valvontatyyppi &&
        localStringToDate(form.control_date) &&
        form.author &&
        phoneRegex.test(form.author_phone) &&
        emailRegex.test(form.author_email)
    );
};

export const formData = (form: any) => {
    return {
        palveluyksikko_id: form.palveluyksikko?.id,
        valvontatyyppi: form.valvontatyyppi?.id,
        control_date: formatDate(localStringToDate(form.control_date)!, "YYYY-MM-DD"),
        author: form.author,
        author_phone: form.author_phone,
        author_email: form.author_email,
        info: form.info,
    };
};
