import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ValvontatapahtumaForm = _resolveComponent("ValvontatapahtumaForm")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _component_q_icon = _resolveComponent("q-icon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Dialog, {
      "modal-open": _ctx.modalOpen,
      title: "Muokkaa valvontatapahtumaa",
      "on-cancel": _ctx.hideDialog,
      "on-save": _ctx.checkError,
      "hide-dialog": _ctx.hideDialog,
      "save-disabled": false,
      "custom-class": "valvonta-dialog",
      loading: _ctx.isUpdating,
      "action-button": "Tallenna muutokset"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ValvontatapahtumaForm, _mergeProps(_ctx.$attrs, {
          modelValue: _ctx.valvontaForm,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.valvontaForm = $event)),
          "show-error": _ctx.showError,
          "filtered-yksikot": _ctx.filteredYksikot
        }), null, 16, ["modelValue", "show-error", "filtered-yksikot"])
      ]),
      _: 1
    }, 8, ["modal-open", "on-cancel", "on-save", "hide-dialog", "loading"]),
    (_ctx.allowAction)
      ? (_openBlock(), _createBlock(_component_q_icon, {
          key: 0,
          name: "fas fa-edit clickable q-pr-sm",
          color: "dark-title",
          size: "20px",
          onClick: _ctx.showDialog
        }, null, 8, ["onClick"]))
      : _createCommentVNode("", true)
  ], 64))
}