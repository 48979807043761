
import { defineComponent, computed, ref, reactive } from "vue";
import Dialog from "@/components/Dialog.vue";
import ValvontatapahtumaForm from "./ValvontatapahtumaForm.vue";
import { formData, filledFields } from "../utils/form";
import { useStore } from "vuex";

export default defineComponent({
    components: { Dialog, ValvontatapahtumaForm },
    props: {
        filteredYksikot: Function,
    },
    setup() {
        const initialState: any = {
            palveluyksikko: null,
            palveluntuottaja: null,
            valvontatyyppi: null,
            organisaatio: null,
            control_date: "",
            author: "",
            author_email: "",
            author_phone: "",
            info: "",
        };
        const showError = ref(false);
        const store = useStore();

        const userOrganisation = computed(() => store.state.user.organisation);

        const form = reactive({ ...initialState, organisaatio: userOrganisation });

        const modalOpen = ref(false);
        const showDialog = () => (modalOpen.value = true);
        const hideDialog = () => (modalOpen.value = false);

        const resetForm = () => {
            Object.assign(form, initialState);
            hideDialog();
        };

        const hasFilledFields = computed(() => filledFields(form));

        const checkError = () => {
            if (hasFilledFields.value) {
                store.dispatch("valvontakalenteri/saveValvontatapahtuma", formData(form));
                resetForm();
            } else {
                showError.value = true;
            }
        };

        return {
            form,
            modalOpen,
            showDialog,
            hideDialog,
            resetForm,
            hasFilledFields,
            checkError,
            showError,
        };
    },
});
