
import { defineComponent, computed, ref, reactive, watch } from "vue";
import Dialog from "@/components/Dialog.vue";
import ValvontatapahtumaForm from "./ValvontatapahtumaForm.vue";
import { formData, filledFields } from "../utils/form";
import { useStore } from "vuex";

export default defineComponent({
    components: { Dialog, ValvontatapahtumaForm },
    props: {
        filteredYksikot: Function,
        form: Object,
        allowAction: Boolean,
    },
    setup(props) {
        const showError = ref(false);
        const store = useStore();

        const isUpdating = computed(() => store.state.valvontakalenteri.updatingValvontatapahtuma);

        watch(
            () => isUpdating.value,
            () => {
                !isUpdating.value && hideDialog();
            }
        );

        const valvontaForm = reactive({});

        const modalOpen = ref(false);
        const showDialog = () => {
            Object.assign(valvontaForm, {
                ...props.form,
            });
            modalOpen.value = true;
        };

        const hideDialog = () => (modalOpen.value = false);

        const hasFilledFields = computed(() => filledFields(valvontaForm));

        const checkError = () => {
            if (hasFilledFields.value) {
                store.dispatch("valvontakalenteri/saveValvontatapahtuma", {
                    ...formData(valvontaForm),
                    uuid: props.form!.uuid,
                });
            } else {
                showError.value = true;
            }
        };

        return {
            valvontaForm,
            modalOpen,
            showDialog,
            hideDialog,
            hasFilledFields,
            checkError,
            showError,
            isUpdating,
        };
    },
});
