
import { defineComponent } from "vue";
import { formattedDate } from "@/utils/date";
import EditValvontatapahtuma from "./EditValvontatapahtuma.vue";
import DeleteValvontatapahtuma from "./DeleteValvontatapahtuma.vue";
import { ValvontatapahtumaSearchResult } from "../@types/ValvontatapahtumaSearchResult";
import useAuthorisation from "@/utils/useAuthorization";
import { ROLE } from "@/constants/roles";

export default defineComponent({
    components: { EditValvontatapahtuma, DeleteValvontatapahtuma },
    props: {
        filteredYksikot: Function,
        valvontatapahtumat: Array,
    },
    setup() {
        const date = (eventDate: string) => formattedDate(eventDate);

        const allowAction = (valvontatapahtuma: ValvontatapahtumaSearchResult) => {
            const { canAccess, organisationMatches } = useAuthorisation(
                [
                    ROLE.TYONTEKIJA,
                    ROLE.KUNTAMANAGER,
                    ROLE.VALVOJA,
                    ROLE.VALVOJAMANAGER,
                    ROLE.SIHTEERI,
                ],
                valvontatapahtuma.organisaatio.id
            );
            return canAccess && organisationMatches;
        };

        return { date, allowAction };
    },
});
