
import { defineComponent, computed, ref } from "vue";
import Dialog from "@/components/Dialog.vue";
import ValvontatapahtumaForm from "./ValvontatapahtumaForm.vue";
import { useStore } from "vuex";

export default defineComponent({
    components: { Dialog, ValvontatapahtumaForm },
    props: {
        filteredYksikot: Function,
        form: Object,
        allowAction: Boolean,
    },
    setup(props) {
        const store = useStore();

        const formData = computed(() => ({
            ...props.form,
        }));

        const modalOpen = ref(false);
        const showDialog = () => (modalOpen.value = true);
        const hideDialog = () => (modalOpen.value = false);

        const deleteValvontatapahtuma = () => {
            store.dispatch("valvontakalenteri/deleteValvontatapahtuma", props.form!.uuid);
        };

        return {
            formData,
            modalOpen,
            showDialog,
            hideDialog,
            deleteValvontatapahtuma,
        };
    },
});
