
import { defineComponent, reactive, computed, toRefs } from "vue";
import { ValvontahakuForm } from "@/modules/valvontatiedot/@types/ValvontahakuForm";
import ValvontatapahtumaList from "../components/ValvontatapahtumaList.vue";
import AddValvontatapahtuma from "../components/AddValvontatapahtuma.vue";
import ValvontaHakuForm from "@/components/ValvontaHakuForm.vue";
import { useStore } from "vuex";
import { VALVONTA_TYPES } from "@/modules/valvontatiedot/constants/Valvontatiedot";
import { Palveluyksikko } from "@/@types/Palveluyksikko";
import { ValvontatapahtumaSearchResult } from "../@types/ValvontatapahtumaSearchResult";
import { mapOptionToId } from "@/utils/option";

export default defineComponent({
    components: {
        ValvontaHakuForm,
        ValvontatapahtumaList,
        AddValvontatapahtuma,
    },
    setup() {
        const initialState: ValvontahakuForm = {
            palveluyksikko: "",
            location: null,
            valvontatyyppi: null,
            date: null,
            organisaatio: null,
            huomioitavaa: false,
        };

        const status = reactive({
            clean: true,
        });

        const form = reactive({ ...initialState });

        const search = () => {
            const parsedForm = {
                valvontatyyppi: form.valvontatyyppi?.id || null,
                palveluyksikko_name: form.palveluyksikko,
                start: form.date?.from || null,
                end: form.date?.to || null,
                location: form.location ? mapOptionToId(form.location) : null,
                organisaatio: form.organisaatio ? mapOptionToId(form.organisaatio) : null,
            };
            store.dispatch("valvontakalenteri/fetchValvontatapahtumat", {
                searchParams: parsedForm,
            });
            status.clean = false;
        };

        const store = useStore();

        const isLoadingResults = computed(
            () => store.state.valvontakalenteri.valvontatapahtumatLoading
        );

        const valvontatapahtumat = computed(() =>
            store.state.valvontakalenteri.valvontatapahtumat.map(
                (vt: ValvontatapahtumaSearchResult) => ({
                    ...vt,
                    valvontatyyppi: VALVONTA_TYPES.find((v) => v.id === vt.valvontatyyppi),
                })
            )
        );

        const palveluyksikot = computed(() => store.state.palveluyksikot);

        const filteredYksikot = (organisationId: number) =>
            palveluyksikot.value.filter(
                (py: Palveluyksikko) => py.palvelutuottaja === organisationId
            );

        const { clean } = toRefs(status);

        return { form, search, valvontatapahtumat, filteredYksikot, isLoadingResults, clean };
    },
});
