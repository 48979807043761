import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ValvontatapahtumaForm = _resolveComponent("ValvontatapahtumaForm")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _component_q_btn = _resolveComponent("q-btn")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Dialog, {
      "modal-open": _ctx.modalOpen,
      title: "Lisää valvontatapahtuma",
      "on-cancel": _ctx.resetForm,
      "on-save": _ctx.checkError,
      "hide-dialog": _ctx.hideDialog,
      "save-disabled": false,
      "custom-class": "valvonta-dialog"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ValvontatapahtumaForm, _mergeProps(_ctx.$attrs, {
          modelValue: _ctx.form,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.form = $event)),
          "show-error": _ctx.showError,
          "filtered-yksikot": _ctx.filteredYksikot
        }), null, 16, ["modelValue", "show-error", "filtered-yksikot"])
      ]),
      _: 1
    }, 8, ["modal-open", "on-cancel", "on-save", "hide-dialog"]),
    _createVNode(_component_q_btn, {
      color: "primary",
      icon: "fas fa-plus",
      "no-caps": "",
      label: "Lisää tapahtuma",
      onClick: _ctx.showDialog
    }, null, 8, ["onClick"])
  ], 64))
}